// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/steve/dev/numbersarenice/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-product-tsx": () => import("/Users/steve/dev/numbersarenice/src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-pages-about-tsx": () => import("/Users/steve/dev/numbersarenice/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-games-tsx": () => import("/Users/steve/dev/numbersarenice/src/pages/games.tsx" /* webpackChunkName: "component---src-pages-games-tsx" */),
  "component---src-pages-games-times-table-speed-tsx": () => import("/Users/steve/dev/numbersarenice/src/pages/games/times-table-speed.tsx" /* webpackChunkName: "component---src-pages-games-times-table-speed-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/steve/dev/numbersarenice/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/steve/dev/numbersarenice/.cache/data.json")

