module.exports = [{
      plugin: require('/Users/steve/dev/numbersarenice/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/steve/dev/numbersarenice/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750},
    },{
      plugin: require('/Users/steve/dev/numbersarenice/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/styles/typography"},
    },{
      plugin: require('/Users/steve/dev/numbersarenice/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/steve/dev/numbersarenice/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"primaryColor":"#0c9ed1"}},
    },{
      plugin: require('/Users/steve/dev/numbersarenice/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-139931274-1","siteSpeedSampleRate":100},
    },{
      plugin: require('/Users/steve/dev/numbersarenice/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
